import React from 'react';
import { CodeSnippet, Link, List, PlatformTab, PropList, PropListItem, Section } from 'docComponents';
import { Text, Overlay, Button, KeyboardShortcut } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../data/snippets/overlay.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Overlay: Overlay, Button: Button }}
        code={snippet}
        platform="react"
        gitHubLink="modal"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="environment" types={['oneOf string']}>
            <Text>Determines the environment of the overlay.</Text>
            <List type="unordered">
              <li>
                <code>light</code> (default)
              </li>
              <li>
                <code>dark</code>
              </li>
            </List>
          </PropListItem>
          <PropListItem name="isOpen" types={['bool']}>
            <Text>Determines whether or not the overlay is displayed.</Text>
          </PropListItem>

          <PropListItem name="allowCloseOnOverlayClick" types={['bool']}>
            <Text>Determines whether or not the user can click the scrim to close the overlay.</Text>
          </PropListItem>

          <PropListItem name="allowCloseOnEsc" types={['bool']}>
            <Text>
              Determines whether or not the user can hit <KeyboardShortcut>Esc</KeyboardShortcut> to close the overlay.
            </Text>
          </PropListItem>

          <PropListItem name="label" types={['string']}>
            <Text>Value used for the aria-label accessibility tag on the Modal element. Defaults to "Hudl Modal".</Text>
          </PropListItem>

          <PropListItem name="title" types={['string']}>
            <Text>
              What is displayed in the header as the overlay title. Will also show the Dismiss button if provided.
            </Text>
          </PropListItem>

          <PropListItem name="parentSelector" types={['func']}>
            <Text>
              A func that will be called to get the parent element that the modal will be attached to. By default, it
              will be attached to the <code>body</code> tag.
            </Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>A unique string to control this overlay in automated tests.</Text>
          </PropListItem>

          <PropListItem name="onAfterOpen" types={['func']}>
            <Text>Called when the modal is opened.</Text>
          </PropListItem>

          <PropListItem name="onClose" types={['func']}>
            <Text>
              Function called when the modal is triggered to close. This is called if and when the <KeyboardShortcut>Esc</KeyboardShortcut> key is pressed,
              the overlay is clicked, the (optional) Cancel button is clicked, or the X is clicked, triggering the modal
              to close. This is where you might update the isOpen value to false.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
